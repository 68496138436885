
html {
  min-height: 100%;
  height: 100%;
  overflow-y: scroll;
}

body {
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  min-height: 100%;
  height: 100vh;
}
