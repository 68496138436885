@import url('https://fonts.googleapis.com/css?family=Abel');

.App {
  text-align: center;
  font-family: 'Abel','Arial';
  font-size: 1.4em;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.Sectionflex {
  flex-grow: 1;
}

.App-footer {
  margin-top: 50px;
  padding: 8px 0;
  text-align: center;
  background: #f0f0f0;
  color: #777;
  font-size: 16px;
}

.App-footer p {
  margin: 0;
}

.App-topsection {
  position: relative;
}

.App-header {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px 0;
  color: whitesmoke;
  display: inline-block;
  line-height: 1;
}

.App-title {
  font-size: 55px;
  display: inline-block;
  width: 90%;
  margin: 5px; 
  animation: fadein 3s;
}

.App-languagemenu button {
  vertical-align: middle;
  font-size: 12px;
  padding: 2px 4px;
  margin-right: 10px;
}

.App-languagemenu img {
  vertical-align: middle;
  height: 15px;
  margin-right: 3px;
}

.App-intro {
  font-size: large;
}

.App-navbar-container {
  position: absolute;
  top: 0;
  width: 100%;
  animation: fadein 3s;
}

.Navarray {
  font-size: x-large;
  padding: 12px 0;
  background-color: rgba(34,34,34,0.5);
}

.Navarray a {
    text-decoration: none;
    color: wheat;
}

.Navarray a:visited {
  color: wheat;
}
.Navarray a:hover {
  background-color: rgba(34,34,34,0.6);
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadein-half {
  from { background-color: rgba(34,34,34,0); }
  to   { background-color: rgba(34,34,34,0.5); }
}

/* Wide screens */
@media screen and (min-width: 1000px) {
  .Navarray a {
    padding-top: inherit;
    padding-bottom: inherit;
    padding-left: 14px;
    padding-right: 14px;
  }

  .navicon {
      display: none
  }
}


/* Narrow screens */
@media screen and (max-width: 1000px) {
  .App-title {
    font-size: 45px;
  }

  .App-navbar-container {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
  }

  .Navarray {
    display: none;
    flex-grow: 1;
    text-align: left;
    background-color: rgba(34,34,34,0.75)
  }
  .Navarray.Nav-slided {
    display: inline-block;
  }

  .Navarray a {
    display: block;
    padding: 10px 25px;
  }
    
  .navicon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 30px 0 0;
  }
}