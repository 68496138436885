/* Wide screens */
@media screen and (min-width: 1000px) {
    .Accommodation-map iframe {
        width: 95%;
        height: 500px;
    }
}

/* Narrow screens */
@media screen and (max-width: 1000px) {
    .Accommodation-map iframe {
        width: 95%;
        height: 400px;
    }
}

.section-accom {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
}

.accom-wh {
    margin-bottom: 20px;
}

.accom-wh-question {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 5px;
}

.accom-wh-data {
    text-align: left;
}