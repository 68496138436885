
.Countdown {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0px 15px;
	margin: 0;
}

.Countdown-section {
	display: inline-block;
	padding: 0px 10px;
}

.Countdown-numbers {
	font-size: 36px;
	color: black;
}

.Countdown-numbers-anim {
	animation-name: timebeats;
	animation-duration: 4s;
}

.Countdown-letters {
	font-size: 15px;
}

@keyframes timebeats {
	from {color: red;}
	to {color:black;}
}

