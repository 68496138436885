
.envelope {
    /* display: inline-block; */
    cursor: pointer;
    /* position: absolute; */
    /* display: inline-block; */
    /* vertical-align: top; */
    /* top: 0;
    right: 0; */
    padding: 7px 13px;
    border-radius: 10px;
    background-color: rgba(34,34,34,0.3);
  }

.envelope.change {
  background-color: transparent;
  border-width: 1px;
  border-color: rgba(245,245,245,0.3);
  border-style: solid;
}
  
  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.2s;
  }
  
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
    background-color: rgb(201, 174, 124);
  }
  
  .change .bar2 {opacity: 0;}
  
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
    background-color: rgb(201, 174, 124);
  }
