.topimg-div {
    position: relative;
    animation-name: topimg-div-anim;
    animation-duration: 2s;
    height: 400px;
}

/* Narrow screens */
@media screen and (max-width: 1000px) {
    .topimg-div {
        animation-name: topimg-div-anim-narrow;
        height: 250px;
    }
}

.topimg-img {
    width: 100%
}

.topimg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    animation-name: imgfader;
    animation-duration: 2s;
}

@keyframes topimg-div-anim {
    from {height: 800px;}
    to {height: 400px;}
}

@keyframes topimg-div-anim-narrow {
    from {height: 500px;}
    to {height: 250px;}
}

@keyframes imgfader {
	from {background-color: rgba(0, 0, 0, 0.0);}
	to {background-color: rgba(0, 0, 0, 0.3);}
}
