
.section-orga {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
}

.orga-savethedate {
    font-size: 45px;
    font-weight: bold;
    margin: 35px 0;
}

.orga-wh {
    margin-bottom: 20px;
}

.orga-wh-question {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 5px;
}

.orga-wh-data {
    text-align: left;
}