.guestarea {
    max-width: 800px;
    width: 95%;
    margin: 0 auto;
}

.persona {
    margin-bottom: 10%;
}

.formstyle-1 label {
    margin-right: 10px;
}
.formstyle-1 input[type="text"],
.formstyle-1 button {
    box-sizing:border-box;
     -moz-box-sizing:border-box;
     -webkit-box-sizing:border-box;
    font: inherit;
    padding: 5px;
    margin-right: 10px;
    border: 1px solid #999;
}

.servererror {
    color: red
}

.wrongcode {
    color: red
}
