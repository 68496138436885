@import url(https://fonts.googleapis.com/css?family=Abel);

html {
  min-height: 100%;
  height: 100%;
  overflow-y: scroll;
}

body {
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  min-height: 100%;
  height: 100vh;
}

.App {
  text-align: center;
  font-family: 'Abel','Arial';
  font-size: 1.4em;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.Sectionflex {
  flex-grow: 1;
}

.App-footer {
  margin-top: 50px;
  padding: 8px 0;
  text-align: center;
  background: #f0f0f0;
  color: #777;
  font-size: 16px;
}

.App-footer p {
  margin: 0;
}

.App-topsection {
  position: relative;
}

.App-header {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 30px 0;
  color: whitesmoke;
  display: inline-block;
  line-height: 1;
}

.App-title {
  font-size: 55px;
  display: inline-block;
  width: 90%;
  margin: 5px; 
  animation: fadein 3s;
}

.App-languagemenu button {
  vertical-align: middle;
  font-size: 12px;
  padding: 2px 4px;
  margin-right: 10px;
}

.App-languagemenu img {
  vertical-align: middle;
  height: 15px;
  margin-right: 3px;
}

.App-intro {
  font-size: large;
}

.App-navbar-container {
  position: absolute;
  top: 0;
  width: 100%;
  animation: fadein 3s;
}

.Navarray {
  font-size: x-large;
  padding: 12px 0;
  background-color: rgba(34,34,34,0.5);
}

.Navarray a {
    text-decoration: none;
    color: wheat;
}

.Navarray a:visited {
  color: wheat;
}
.Navarray a:hover {
  background-color: rgba(34,34,34,0.6);
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadein-half {
  from { background-color: rgba(34,34,34,0); }
  to   { background-color: rgba(34,34,34,0.5); }
}

/* Wide screens */
@media screen and (min-width: 1000px) {
  .Navarray a {
    padding-top: inherit;
    padding-bottom: inherit;
    padding-left: 14px;
    padding-right: 14px;
  }

  .navicon {
      display: none
  }
}


/* Narrow screens */
@media screen and (max-width: 1000px) {
  .App-title {
    font-size: 45px;
  }

  .App-navbar-container {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
  }

  .Navarray {
    display: none;
    flex-grow: 1;
    text-align: left;
    background-color: rgba(34,34,34,0.75)
  }
  .Navarray.Nav-slided {
    display: inline-block;
  }

  .Navarray a {
    display: block;
    padding: 10px 25px;
  }
    
  .navicon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 30px 0 0;
  }
}

.Countdown {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0px 15px;
	margin: 0;
}

.Countdown-section {
	display: inline-block;
	padding: 0px 10px;
}

.Countdown-numbers {
	font-size: 36px;
	color: black;
}

.Countdown-numbers-anim {
	animation-name: timebeats;
	animation-duration: 4s;
}

.Countdown-letters {
	font-size: 15px;
}

@keyframes timebeats {
	from {color: red;}
	to {color:black;}
}


.topimg-div {
    position: relative;
    animation-name: topimg-div-anim;
    animation-duration: 2s;
    height: 400px;
}

/* Narrow screens */
@media screen and (max-width: 1000px) {
    .topimg-div {
        animation-name: topimg-div-anim-narrow;
        height: 250px;
    }
}

.topimg-img {
    width: 100%
}

.topimg-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    animation-name: imgfader;
    animation-duration: 2s;
}

@keyframes topimg-div-anim {
    from {height: 800px;}
    to {height: 400px;}
}

@keyframes topimg-div-anim-narrow {
    from {height: 500px;}
    to {height: 250px;}
}

@keyframes imgfader {
	from {background-color: rgba(0, 0, 0, 0.0);}
	to {background-color: rgba(0, 0, 0, 0.3);}
}


.envelope {
    /* display: inline-block; */
    cursor: pointer;
    /* position: absolute; */
    /* display: inline-block; */
    /* vertical-align: top; */
    /* top: 0;
    right: 0; */
    padding: 7px 13px;
    border-radius: 10px;
    background-color: rgba(34,34,34,0.3);
  }

.envelope.change {
  background-color: transparent;
  border-width: 1px;
  border-color: rgba(245,245,245,0.3);
  border-style: solid;
}
  
  .bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.2s;
  }
  
  .change .bar1 {
    transform: rotate(-45deg) translate(-9px, 6px);
    background-color: rgb(201, 174, 124);
  }
  
  .change .bar2 {opacity: 0;}
  
  .change .bar3 {
    transform: rotate(45deg) translate(-8px, -8px);
    background-color: rgb(201, 174, 124);
  }


.section-orga {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
}

.orga-savethedate {
    font-size: 45px;
    font-weight: bold;
    margin: 35px 0;
}

.orga-wh {
    margin-bottom: 20px;
}

.orga-wh-question {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 5px;
}

.orga-wh-data {
    text-align: left;
}
.leaflet-container {
  height: 600px;
  width: 100%;
}

.guestarea {
    max-width: 800px;
    width: 95%;
    margin: 0 auto;
}

.persona {
    margin-bottom: 10%;
}

.formstyle-1 label {
    margin-right: 10px;
}
.formstyle-1 input[type="text"],
.formstyle-1 button {
    box-sizing:border-box;
     -moz-box-sizing:border-box;
     -webkit-box-sizing:border-box;
    font: inherit;
    padding: 5px;
    margin-right: 10px;
    border: 1px solid #999;
}

.servererror {
    color: red
}

.wrongcode {
    color: red
}

/* Wide screens */
@media screen and (min-width: 1000px) {
    .Accommodation-map iframe {
        width: 95%;
        height: 500px;
    }
}

/* Narrow screens */
@media screen and (max-width: 1000px) {
    .Accommodation-map iframe {
        width: 95%;
        height: 400px;
    }
}

.section-accom {
    width: 95%;
    max-width: 800px;
    margin: 0 auto;
}

.accom-wh {
    margin-bottom: 20px;
}

.accom-wh-question {
    font-size: larger;
    font-weight: bold;
    margin-bottom: 5px;
}

.accom-wh-data {
    text-align: left;
}
